import chapa from "../assets/chapa.png";
// import telebirr from "../assets/telebirr.png";

const Channels = [
  {
    id: "1001",
    name: "Chapa",
    icon: chapa,
  },
];
export default Channels;
