const Withdrwals = [
    {
        id: "01",
        date: "07/09/2024",
        amount: "2000",
        channel: "Telebirr",
        accountNo: "3276538746534",
        pdate: "07/09/2024",


    },
    {
        id: "02",
        date: "07/09/2024",
        amount: "1000",
        channel: "Telebirr",
        accountNo: "3276538746534",
        pdate: "07/09/2024",
    },
    {
        id: "03",
        date: "07/09/2024",
        amount: "3000",
        channel: "Telebirr",
        accountNo: "3276538746534",
        pdate: "07/09/2024",
    },
    {
        id: "04",
        date: "07/09/2024",
        amount: "800",
        channel: "Telebirr",
        accountNo: "3276538746534",
        pdate: "07/09/2024",
    },
    {
        id: "04",
        date: "07/09/2024",
        amount: "800",
        channel: "Telebirr",
        accountNo: "3276538746534",
        pdate: "07/09/2024",
    },
];

export default Withdrwals;